<template>
  <div ref="wrapper">
    <div v-if="field.label && !isModal" class="row align-items-center">
      <div class="col">
        <label>
          {{ field.label }}
          <span v-if="field.required" class="red--text">*</span>
          <i
            v-if="field.helpSnippet"
            v-b-popover.hover.top="$t(field.helpSnippet)"
            class="fal fa-question-circle ml-1"
          />
        </label>
      </div>
    </div>
    <div class="d-flex flex-nowrap">
      <div style="flex-grow: 1; max-width: 100%">
        <div>
          <h4 v-if="elementSelectorActive">
            {{ $t("workflowElements.clickOnTheElement") }}
          </h4>
          <span v-if="elementSelectorNodeFound">
            <div class="d-flex align-items-center flex-wrap mb-8">
              <div
                class="cursor-pointer symbol symbol-50 symbol-light mr-5"
                @click="blinkElementSelectorNode"
              >
                <span class="symbol-label">
                  <img
                    :src="selectNodeElementIcon"
                    class="h-50 align-self-center"
                    alt=""
                  />
                </span>
              </div>
              <div class="d-flex flex-column flex-grow-1 mr-2">
                <a
                  class="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                  @click="blinkElementSelectorNode"
                >
                  {{ elementSelectorNodeFound.attrs.data.planning.label }}
                </a>
                <span
                  class="cursor-pointer text-muted font-weight-bold"
                  @click="blinkElementSelectorNode"
                >
                  {{ elementSelectorNodeFound.attrs.label }}
                </span>
              </div>
              <button
                v-if="!elementSelectorActive"
                class="btn btn-primary mt-1 my-lg-0 my-2"
                @click="selectNodeElement"
              >
                <i class="fal fa-pencil" />
              </button>
              <b-button
                v-if="elementSelectorActive"
                variant="primary"
                @click="selectNodeElementAbort"
              >
                {{ $t("general.cancel") }}
              </b-button>
            </div>
          </span>
          <span v-else>
            <b-button
              v-if="!elementSelectorActive"
              variant="primary"
              @click="selectNodeElement"
            >
              {{ $t("workflowElements.selectElement") }}
            </b-button>
            <b-button
              v-if="elementSelectorActive"
              variant="primary"
              @click="selectNodeElementAbort"
            >
              {{ $t("general.cancel") }}
            </b-button>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "@/main";

export default {
  props: {
    field: {
      type: Object
    },
    configValues: {
      type: Array
    },
    outputValues: {
      type: Array,
      default: () => []
    },
    debugValues: {
      type: Array,
      default: () => []
    },
    areaInvalid: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    node: {
      type: Object,
      default: () => {}
    },
    conditionValue: {
      type: Boolean,
      default: false
    },
    isModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      edited: false,
      value: "",
      configValue: null,
      valueIndex: null,
      selectedConfigValue: undefined,
      elementSelectorNodeFound: null,
      elementSelectorStrokeOriginalColor: "#9babbd",
      elementSelectorStrokeColorInterval: null,
      elementSelectorActive: false
    };
  },
  computed: {
    selectNodeElementIcon() {
      if (!this.elementSelectorNodeFound) {
        return null;
      }
      return this.elementSelectorNodeFound.attrs.fillPatternImage.src;
    }
  },
  watch: {
    value: function () {
      this.edited = true;
      let newValue = this.value;

      if (typeof newValue !== "boolean" && Number(newValue)) {
        newValue = parseFloat(newValue);
      } else if (newValue === "true" || newValue === "false") {
        newValue = Boolean(newValue);
      }

      this.$set(this.field, "value", newValue);
      this.$emit("change", newValue);
    }
  },
  mounted() {
    this.value = this.field.value ?? "";
    this.elementSelectorNodeFound = this.$root.$refs.WFDEditor.nodes.find(
      x => x.attrs.data.hash === this.field.value
    );
    bus.$on("nodeSelected", this.nodeSelected);
  },
  beforeDestroy() {
    this.blinkElementSelectorNodeStop();
  },
  destroyed() {
    bus.$off("nodeSelected", this.nodeSelected);
  },
  methods: {
    blinkElementSelectorNode() {
      if (this.elementSelectorStrokeColorInterval) {
        return;
      }
      this.elementSelectorStrokeColorInterval = setInterval(() => {
        let newColor =
          this.elementSelectorNodeFound.attrs.stroke ===
          this.elementSelectorStrokeOriginalColor
            ? "#ff0000"
            : this.elementSelectorStrokeOriginalColor;
        this.elementSelectorNodeFound.stroke(newColor);
        this.$root.$refs.WFDEditor.stage.batchDraw();
      }, 150);
      setTimeout(() => {
        this.blinkElementSelectorNodeStop();
      }, 3000);
    },
    blinkElementSelectorNodeStop() {
      if (!this.elementSelectorNodeFound) {
        return;
      }
      clearInterval(this.elementSelectorStrokeColorInterval);
      this.elementSelectorStrokeColorInterval = null;
      this.elementSelectorNodeFound.stroke(
        this.elementSelectorStrokeOriginalColor
      );
      this.$root.$refs.WFDEditor.stage.batchDraw();
    },
    selectNodeElement() {
      this.elementSelectorActive = true;
      document.getElementById("konva").style.cursor = "crosshair";
      this.$root.$refs.WFDEditor.preventNodeClick = true;
    },
    selectNodeElementAbort() {
      this.elementSelectorActive = false;
      document.getElementById("konva").style.cursor = "pointer";
      this.$root.$refs.WFDEditor.preventNodeClick = false;
    },
    nodeSelected(node) {
      if (!this.elementSelectorActive) {
        return;
      }
      node.stroke(this.elementSelectorStrokeOriginalColor);
      this.elementSelectorActive = false;
      this.elementSelectorNodeFound = node;
      document.getElementById("konva").style.cursor = "pointer";
      this.value = this.elementSelectorNodeFound.attrs.id;
      this.field.value = this.elementSelectorNodeFound.attrs.id;
      this.$root.$refs.WFDEditor.preventNodeClick = false;
    }
  }
};
</script>
